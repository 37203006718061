<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade class=" mt-2" style="height:100vh">
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Create Workshop</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="surveyRules">
        <b-form>
          <b-row class="mt-2">
            <!-- Course Name -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group>
                <label for="coursName">Course Name</label>
                <validation-provider name="coursName" #default="{ errors }" rules="required">
                  <b-form-input id="coursName" type="text" v-model="workshopData.courseName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Course Abbreviation -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Course Abbreviation" label-for="courseAbbreviation">
                <validation-provider name="courseAbbreviation" #default="{ errors }" rules="required">
                  <b-form-input id="courseAbbreviation" type="text" v-model="workshopData.abbreviation"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Course Delivery Option Code -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Course Delivery Option Code" label-for="courseDeOpsCode">
                <validation-provider name="courseDeOpsCode" #default="{ errors }" rules="required">
                  <b-form-input id="courseDeOpsCode" type="text" v-model="workshopData.optionCode"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Base Price (30 Participants) -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Base Price (30 Participants)" label-for="basePrice">
                <validation-provider name="basePrice" #default="{ errors }" rules="required">
                  <b-form-input id="basePrice" type="number" v-model="workshopData.price"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Extra Price Per Person -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Extra Price Per Person" label-for="extraPricePP">
                <validation-provider name="extraPricePP" #default="{ errors }" rules="required">
                  <b-form-input id="extraPricePP" type="number" v-model="workshopData.extraPricePP"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xl="12">
              <hr />
            </b-col>

            <!-- RC Allocation -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group>
                <label for="rcAllocation">RC Allocation</label>
                <validation-provider name="rcAllocation" #default="{ errors }" rules="required">
                  <b-form-input id="rcAllocation" type="text" v-model="workshopData.rcAllocationPrice"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- COFV Price -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="COFV Price" label-for="cofvPrice">
                <validation-provider name="cofvPrice" #default="{ errors }" rules="required">
                  <b-form-input id="cofvPrice" type="number" v-model="workshopData.cofvPrice"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Publications Price -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Publications Price" label-for="publicationPrice">
                <validation-provider name="publicationPrice" #default="{ errors }" rules="required">
                  <b-form-input id="publicationPrice" type="number" v-model="workshopData.publicationsPrice"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Avenue A Price -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Avenue A Price" label-for="avenueAPrice">
                <validation-provider name="avenueAPrice" #default="{ errors }" rules="required">
                  <b-form-input id="avenueAPrice" type="number" v-model="workshopData.avenuePrice"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Fly Five Price -->
            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group label="Fly Five Price" label-for="flyFivePrice">
                <validation-provider name="flyFivePrice" #default="{ errors }" rules="required">
                  <b-form-input id="flyFivePrice" type="number" v-model="workshopData.flyFivePrice"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mb-5 mt-2">
              <b-button type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const WORKSHOP_APP_STORE_MODULE_NAME = 'workshop';
    // Register module
    if (!store.hasModule(WORKSHOP_APP_STORE_MODULE_NAME)) store.registerModule(WORKSHOP_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORKSHOP_APP_STORE_MODULE_NAME)) store.unregisterModule(WORKSHOP_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var workshopData = {
      coursName: null,
      courseAbbreviation: null,
      courseDeOpsCode: null,
      basePrice: null,
      extraPricePP: null,
      rcAllocation: null,
      cofvPrice: null,
      publicationPrice: null,
      avenueAPrice: null,
      flyFivePrice: null,
    };

    return {
      workshopData: workshopData,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      formShow: false,
      groups: [],
      allGroupsData: [],
      fields: [
        {
          key: 'questions',
          label: 'questions',
          thStyle: { width: '80%' },
        },
        {
          key: 'answer',
          label: 'answer',
          thStyle: { width: '20%' },
        },
      ],
    };
  },
  mounted() {
    //this.getGroupWithQuestions();
    // this.getAllGroupsData();
  },

  created() {
    this.getByIdWokshop();
  },

  watch: {
    // 'workshopData.event': {
    //   handler: function(val, before) {
    //   },
    // },
  },

  methods: {
    formSubmitted() {
      this.formShow = true;
      const self = this;
      this.$refs.surveyRules.validate().then((success) => {
        if (success) {
          this.formShow = false;
        } else {
          this.formShow = false;
        }
      });
    },

    getByIdWokshop() {
      this.formShow = true;
      if (router.currentRoute.params.id) {
        store.dispatch('workshop/detailWorkshop', { id: router.currentRoute.params.id }).then((response) => {
          this.workshopData = response.data;
        });

        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

#eventLabel__BV_label_ {
  text-decoration: underline;
}
</style>
