var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:" mt-2",staticStyle:{"height":"100vh"},attrs:{"show":_vm.formShow,"rounded":"true","fixed":true,"no-fade":""}},[_c('b-card',[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Create Workshop")])])])])]),_c('validation-observer',{ref:"surveyRules"},[_c('b-form',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"coursName"}},[_vm._v("Course Name")]),_c('validation-provider',{attrs:{"name":"coursName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"coursName","type":"text"},model:{value:(_vm.workshopData.courseName),callback:function ($$v) {_vm.$set(_vm.workshopData, "courseName", $$v)},expression:"workshopData.courseName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Course Abbreviation","label-for":"courseAbbreviation"}},[_c('validation-provider',{attrs:{"name":"courseAbbreviation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"courseAbbreviation","type":"text"},model:{value:(_vm.workshopData.abbreviation),callback:function ($$v) {_vm.$set(_vm.workshopData, "abbreviation", $$v)},expression:"workshopData.abbreviation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Course Delivery Option Code","label-for":"courseDeOpsCode"}},[_c('validation-provider',{attrs:{"name":"courseDeOpsCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"courseDeOpsCode","type":"text"},model:{value:(_vm.workshopData.optionCode),callback:function ($$v) {_vm.$set(_vm.workshopData, "optionCode", $$v)},expression:"workshopData.optionCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Base Price (30 Participants)","label-for":"basePrice"}},[_c('validation-provider',{attrs:{"name":"basePrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basePrice","type":"number"},model:{value:(_vm.workshopData.price),callback:function ($$v) {_vm.$set(_vm.workshopData, "price", $$v)},expression:"workshopData.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Extra Price Per Person","label-for":"extraPricePP"}},[_c('validation-provider',{attrs:{"name":"extraPricePP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"extraPricePP","type":"number"},model:{value:(_vm.workshopData.extraPricePP),callback:function ($$v) {_vm.$set(_vm.workshopData, "extraPricePP", $$v)},expression:"workshopData.extraPricePP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"rcAllocation"}},[_vm._v("RC Allocation")]),_c('validation-provider',{attrs:{"name":"rcAllocation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rcAllocation","type":"text"},model:{value:(_vm.workshopData.rcAllocationPrice),callback:function ($$v) {_vm.$set(_vm.workshopData, "rcAllocationPrice", $$v)},expression:"workshopData.rcAllocationPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"COFV Price","label-for":"cofvPrice"}},[_c('validation-provider',{attrs:{"name":"cofvPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cofvPrice","type":"number"},model:{value:(_vm.workshopData.cofvPrice),callback:function ($$v) {_vm.$set(_vm.workshopData, "cofvPrice", $$v)},expression:"workshopData.cofvPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Publications Price","label-for":"publicationPrice"}},[_c('validation-provider',{attrs:{"name":"publicationPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"publicationPrice","type":"number"},model:{value:(_vm.workshopData.publicationsPrice),callback:function ($$v) {_vm.$set(_vm.workshopData, "publicationsPrice", $$v)},expression:"workshopData.publicationsPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Avenue A Price","label-for":"avenueAPrice"}},[_c('validation-provider',{attrs:{"name":"avenueAPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"avenueAPrice","type":"number"},model:{value:(_vm.workshopData.avenuePrice),callback:function ($$v) {_vm.$set(_vm.workshopData, "avenuePrice", $$v)},expression:"workshopData.avenuePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Fly Five Price","label-for":"flyFivePrice"}},[_c('validation-provider',{attrs:{"name":"flyFivePrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"flyFivePrice","type":"number"},model:{value:(_vm.workshopData.flyFivePrice),callback:function ($$v) {_vm.$set(_vm.workshopData, "flyFivePrice", $$v)},expression:"workshopData.flyFivePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-5 mt-2",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }